@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "base";
@import "layout";
@import "clayer";
@import "icons";
@import "rangeslider";


html,
body {
  height: 100%;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;

  > header {
    flex-shrink: 0;
  }
  > section {
    flex: 1 0 auto;
  }
  > footer {
    flex-shrink: 0;
  }
}

@mixin crypted-status-variant($status-color) {
  color: theme-color-level($status-color, $alert-color-level);
  @include gradient-bg(theme-color-level($status-color, $alert-bg-level));
  border-color: theme-color-level($status-color, $alert-border-level);
}

.error {
  color: $danger;
}

.ico-logo {
  height: 64px;
  width: auto;
}

.pointer {
  cursor: pointer;
}

.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

.custom-checkbox.custom-control>span.required:after,
label.required:after {
  content: "*";
  display: inline-block;
  color: $warning;
  padding-left: 3px;
  font-size: 1.75em;
  line-height: 0;
}

.input-group {
  &.crypted {
    .input-group-prepend {
      flex-grow: 1;
      margin-right: 0;

      .input-group-text {
        flex-grow: 1;
        border-radius: 0.25rem;
        cursor: pointer;

        &.status-FILLED {
          @include crypted-status-variant('warning');
        }

        &.status-REFUSED {
          @include crypted-status-variant('danger');
        }

        &.status-ACCEPTED {
          @include crypted-status-variant('success');
        }
      }
    }

    .input-group-append {
      margin-left: -2px;

      .input-group-text {
        cursor: pointer;
      }
    }
  }
}

.iframe-extra-document {
  min-width: 100%;
  min-height: 300px;
  border: $input-border-width solid $input-border-color;
  @include border-radius($input-border-radius, 0);
}

.wrapper-signature-pad {
  border: $input-border-width solid $input-border-color;
  @include border-radius($input-border-radius, 0);

  &.is-invalid {
    border-color: $form-feedback-invalid-color;

    &~div .invalid-feedback {
      display: block;
    }
  }

  canvas {
    cursor: crosshair;
  }
}

.react-datepicker-wrapper {
  display: block;

  &.is-invalid {
    &+.invalid-feedback {
      display: block;
    }
  }

  .input-group>& {
    flex: 1 1 auto;

    & .form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.card.is-not-active,
.list-group-item.is-not-active {
  background-image: repeating-linear-gradient(-45deg, transparent 0 20px, rgba(0, 0, 0, 0.05) 20px 40px);
  pointer-events: none;

  &.current {
    background-color: rgba(0, 255, 0, 0.2);
  }
}

.invalid-feedback {
  display: block;
}