.clayer-app {

  .main-logo {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 6rem;
      margin: 2rem;
    }

    .logo-title {
      color: $gray-600;

      .title {
        display: block;
        font-size: $h3-font-size;
        font-family: $headings-font-family;
        text-align: left;
      }

      .sub-title {
        display: block;
        text-align: left;
      }
    }
  }

  section {
    &#issuance-wizard-steps {
      background: $gray-200;

      .steps {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        position: relative;
        z-index: 1;

        &::after {
          content: '';
          position: absolute;
          left: -15px;
          right: -15px;
          top: 0.75rem;
          height: 0.5rem;
          background: $gray-500;
          z-index: -1;
        }

        &.complete {
          &::after {
            background: $secondary;
          }
        }

        .step {
          width: 4rem;
          height: 4rem;
          padding-top: 2.5rem;
          position: relative;
          text-align: center;
          z-index: 2;
          line-height: 40px;
          text-transform: uppercase;

          &::before {
            content: '';
            position: absolute;
            top: -8px;
            right: 0.5rem;
            border-radius: 50%;
            background-color: $gray-500;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: auto 1.3rem;
            width: 3rem;
            height: 3rem;
            margin: auto;
          }

          &.step-1 {
            z-index: 5;

            &::before {
              background-image: url('../images/wizard-step-details.svg');
            }
          }

          &.step-2 {
            z-index: 4;

            &::before {
              background-image: url('../images/wizard-step-minting.svg');
            }
          }

          &.step-3 {
            z-index: 3;

            &::before {
              background-image: url('../images/wizard-step-sale.svg');
            }
          }

          &.step-4 {
            z-index: 2;

            &::before {
              background-image: url('../images/wizard-step-review.svg');
            }
          }

          &.step-5 {
            z-index: 1;

            &::before {
              background-image: url('../images/wizard-step-deploy.svg');
            }
          }

          &.active {
            color: darken($secondary, 5);
            font-weight: bold;


            &::before {
              background-color: $secondary;
            }

            &::after {
              content: '';
              position: absolute;
              left: -9999px;
              top: 0.75rem;
              right: 2rem;
              height: 0.5rem;
              background-color: $secondary;
              z-index: -1;
            }
          }
        }
      }
    }

    &#issuance-wizard-forms {
      background: $gray-200;

      .card {
        &.wizard-form-card {
          border: none;

          .card-header {
            border-radius: 0;
            background: $gray-600;
            color: $light;
            font-size: $h3-font-size;
            text-transform: uppercase;
            letter-spacing: 0.2rem;
          }

          .card-body {
            .form-control {
              font-weight: 600;

              &:focus,
              &:active {
                box-shadow: none;
                border-color: $secondary;
              }
            }

            .form-text {
              font-weight: 600;
            }

            fieldset {
              border: 1px solid $gray-500;
              padding: 1rem;

              legend {
                font-size: 1.1rem;
              }

              &.sub-section {
                label {
                  font-weight: 600;
                }
              }
            }

            table {
              th {
                font-weight: 400;
              }

              td {
                font-weight: 600;
              }
            }
          }

          .card-footer {
            padding-top: 2rem;
            background: $gray-200;
            border: none;
            text-align: center;
          }
        }
      }
    }
  }

  .popover {
    .popover-body {
      font-weight: 600;
    }
  }

  [class*="col-"] {
    min-height: 30px;
  }
}