footer {
  background: $dark;
  color: $light;
  padding-top: 5rem;
  padding-bottom: 5rem;

  .section-content-wrapper {
    .section-content {
      &:not(:first-child), &:not(:last-child) {
        padding-left: 2rem;
        padding-right: 2rem;
      }


      img {
        &.footer-logo {
          width: 180px;
          max-width: 100%;
          display: block;
          margin: 0 auto 2rem;
        }

        &.footer-brand-logo {
          width: 120px;
        }
      }

      ul {
        margin-left: 0;
        padding-left: 0;

        li {
          list-style: none;
          margin-left: 0;
          padding-left: 0;
        }
      }

      a {
        color: $light;
      }
    }
  }
}