h1, h2, h3 {
  font-weight: $font-weight-normal;
  letter-spacing: 0.4rem;
}

h2 {
  font-weight: 600;

  span {
    font-weight: $font-weight-normal;
  }

  & + .heading-detail {
    color: $body-color;
    font-size: $h2-font-size;
    font-weight: $font-weight-light;
    margin-top: -2rem;
    margin-bottom: 2rem;
  }
}

.sub-heading {
  font-weight: $font-weight-bold;
  color: $primary;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

b, strong {
  font-weight: 500;
}

a {
  text-decoration: none;
  color: $primary;
  transition: all 0.3s ease-in-out;
  position: relative;

  &:hover, &:focus, &:active {
    text-decoration: none;
    color: transparentize($primary, 0.2);
  }

  &.more {
    &::after {
      content: '';
      display: inline-block;
      background: url('../images/link-arrow-red.svg') no-repeat center right;
      background-size: contain;
      width: 2.5rem;
      height: 2.5rem;
      margin-left: 1.5rem;
      margin-bottom: -1rem;
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      &::after {
        margin-left: 2.5rem;
      }
    }
  }
}

.animation-target {
  visibility: hidden;

  &.animated {
    visibility: visible !important;
  }
}
