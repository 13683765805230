/**
 * Use Nunito font family until we have license for Futura PT
 */
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,600i,700,800&display=swap');

@font-face {
  font-family: 'Futura';
  src: url('../fonts/FuturaPTMedium.otf');
}
@font-face {
  font-family: 'Futura';
  src: url('../fonts/FuturaPTLight.otf');
  font-weight: 300;
}
@font-face {
  font-family: 'Futura';
  src: url('../fonts/FuturaPTBold.otf');
  font-weight: 500;
}
@font-face {
  font-family: 'Futura';
  src: url('../fonts/FuturaPTMediumOblique.otf');
  font-style: italic;
}
@font-face {
  font-family: 'Futura';
  src: url('../fonts/FuturaPTLightOblique.otf');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Futura';
  src: url('../fonts/FuturaPTBoldOblique.otf');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Futura';
  src: url('../fonts/FuturaPTBook.otf');
  font-weight: 600;
}
@font-face {
  font-family: 'Futura';
  src: url('../fonts/FuturaPTBookOblique.otf');
  font-weight: 600;
  font-style: italic;
}