section {
  &.content-section {
    &.with-bg {
      color: $light;

      &.bg-l-40 {
        &.golden-bg {
          background: $secondary;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            background: linear-gradient(to right, $secondary 40%, transparent 40%, transparent 100%);
          }
        }

        &.black-bg {
          background: $black;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            background: linear-gradient(to right, $black 40%, transparent 40%, transparent 100%);
          }
        }

        &.red-bg {
          background: $primary;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            background: linear-gradient(to right, $primary 40%, transparent 40%, transparent 100%);
          }
        }
      }

      &.bg-l-50 {
        &.golden-bg {
          background: $secondary;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            background: linear-gradient(to right, $secondary 50%, transparent 50%, transparent 100%);
          }
        }

        &.black-bg {
          background: $black;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            background: linear-gradient(to right, $black 50%, transparent 50%, transparent 100%);
          }
        }

        &.red-bg {
          background: $primary;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            background: linear-gradient(to right, $primary 50%, transparent 50%, transparent 100%);
          }
        }
      }

      &.bg-r-40 {
        &.golden-bg {
          background: $secondary;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            background: linear-gradient(to left, $secondary 40%, transparent 40%, transparent 100%);
          }
        }

        &.black-bg {
          background: $black;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            background: linear-gradient(to left, $black 40%, transparent 40%, transparent 100%);
          }
        }

        &.red-bg {
          background: $primary;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            background: linear-gradient(to left, $primary 40%, transparent 40%, transparent 100%);
          }
        }
      }

      &.bg-r-50 {
        &.golden-bg {
          background: $secondary;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            background: linear-gradient(to left, $secondary 50%, transparent 50%, transparent 100%);
          }
        }

        &.black-bg {
          background: $black;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            background: linear-gradient(to left, $black 50%, transparent 50%, transparent 100%);
          }
        }

        &.red-bg {
          background: $primary;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            background: linear-gradient(to left, $primary 50%, transparent 50%, transparent 100%);
          }
        }
      }
    }

    &#new-framework {
      color: $body-color;
      position: relative;
      z-index: 3;
      transition: all 0.3s ease-in-out;

      &::after {
        display: none;
        content: '';
        background: url('../images/new-standard-bg.jpg') no-repeat center top;
        background-size: cover;
        position: absolute;
        left: 40%;
        width: 40%;
        top: -5rem;
        bottom: 10rem;
        transition: all 0.2s ease-in-out;

        @include media-breakpoint-up($mobile-view-breakpoint) {
          display: block;
        }
      }

      .section-content-wrapper {
        .section-content {
          padding-top: 5rem;
          padding-right: 5rem;
          padding-bottom: 5rem;

          h2 {
            text-transform: uppercase;

            span {
              color: $light;
              font-weight: $font-weight-light;
            }
          }

          .content-body {
            margin-top: 2.5rem;
            margin-bottom: 2.5rem;

            .framework-info {
              .framework-info-col {
                transition: all 0.1s ease-in-out;
              }
            }

            a {
              &.more {
                margin-top: 2rem;
                line-height: 3rem;

                &.open {
                  &::after {
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }

          .content-footer {
            margin-top: 2rem;
            text-align: right;

            img {
              width: 150px;
              height: auto;
              opacity: 0.5;
            }
          }
        }
      }

      &.open {
        @include media-breakpoint-up($mobile-view-breakpoint) {
          /*background: linear-gradient(to left, $secondary 100%, transparent 100%, transparent 100%) !important;*/
          background: $secondary;
          overflow-x: hidden;

          &::after {
            left: 100%;
            opacity: 0;
          }

          .section-content {
            flex: 0 0 100% !important;
            max-width: 100% !important;

            .content-body {
              .framework-info {
                .framework-info-col {
                  flex: 0 0 50%;
                  max-width: 50%;
                }
              }
            }
          }
        }

        & + section {
          &#regulatory-comfort {
            .section-content-wrapper {
              .section-content {
                margin-top: 0 !important;
              }
            }
          }
        }
      }
    }

    &#regulatory-comfort {
      position: relative;
      z-index: 1;
      //margin-bottom: 2rem;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        //margin-bottom: 10rem;
      }

      .section-content-wrapper {
        position: relative;

        &::before {
          display: none;
          content: '';
          background: url('../images/regulatory-comfort-bg.jpg') no-repeat center center;
          background-size: cover;
          position: absolute;
          left: 0;
          width: 60%;
          top: -4rem;
          bottom: 0;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            display: block;
          }
        }

        .section-content {
          margin-top: 0;
          padding: 2rem;
          transition: all 0.2s ease-in-out;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            margin-top: -6.5rem;
            padding-right: 4rem;
            padding-left: 3rem;
            padding-bottom: 5rem;
          }

          .mobile-image {
            width: 100%;
            overflow: hidden;
            padding-bottom: 2rem;
            margin-top: -2.5rem;

            img {
              max-width: 100%;
            }
          }

          h2 {
            text-transform: uppercase;

            span {
              color: $primary;
            }
          }

          .content-body {
            margin-top: 2.5rem;
            margin-bottom: 2.5rem;

            a {
              &.more {
                margin-top: 2rem;
                line-height: 3rem;
              }
            }

            ul {
              margin-left: 0;
              padding-left: 0;

              li {
                list-style: none;
                margin-left: 0;
                padding-left: 1rem;
                position: relative;

                &:before {
                  content: '-';
                  position: absolute;
                  left: 0;
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }

    &#services {
      position: relative;
      padding-top: 5rem;
      z-index: 3;

      &::before {
        content: '';
        background: url('../images/quad.svg') no-repeat center 3rem;
        background-size: 15rem;
        position: absolute;
        transition: all 0.3s ease-in-out;
        right: 5%;
        width: 20rem;
        height: 30rem;
        top: 5rem;

        @include media-breakpoint-up($mobile-view-breakpoint) {
          right: 70%;
          width: 20rem;
          height: 30rem;
          top: 10rem;
        }
      }

      &:hover {
        &::before {
          top: 3rem;
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);

          @include media-breakpoint-up($mobile-view-breakpoint) {
            top: 7rem;
            -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
          }
        }
      }

      .section-content-wrapper {
        .section-content {
          margin-top: 6rem;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            margin-top: 10rem;
          }

          h2 {
            margin-top: 5rem;
            text-transform: uppercase;
          }

          .services-items {
            @include media-breakpoint-up($mobile-view-breakpoint) {
              height: 31.5rem;
              margin-left: -20%;
              margin-top: -2em;
            }


            .service {
              height: 20rem;
              padding: 9.5rem 1rem 1rem 1rem;
              text-align: center;
              cursor: pointer;
              transition: all 0.1s ease-in-out;

              @include media-breakpoint-up($mobile-view-breakpoint) {

                position: absolute;
                width: 50%;

                &.right {
                  margin-left: 50%;
                }

                &.bottom {
                  margin-top: 20rem;
                }

                &.active {
                  transform: scale(1.1);
                }

              }


              .description {
                display: none;
              }

              .icon-close {
                background: url('../images/cross.svg') no-repeat center center;
                background-size: contain;
                width: 40px;
                height: 40px;
                border: none;
                position: absolute;
                top: 20px;
                right: 20px;
                outline: none;

                &:hover {
                  transform: scale(1.1) rotate(180deg);
                  transition-duration: .3s;
                }
              }

              &.show-description {

                &:before,
                &:after {
                  display: none;
                }

                & > h3 {
                  display: none;
                }


                height: auto;
                padding: 110px 48px;

                @include media-breakpoint-up($mobile-view-breakpoint) {
                  height: 44em;
                  padding: 110px;
                  transform: scale(1.1);
                }

                width: 100%;
                z-index: 100;

                margin: 0;


                .description {
                  display: block;
                  text-align: left;
                  color: $white;

                  h3 {
                    text-transform: uppercase;
                    color: $black;
                    font-weight: 300;
                    margin-bottom: 10px;
                  }
                }
              }

              @include media-breakpoint-up($mobile-view-breakpoint) {
                padding-top: 11.5rem;
              }

              h3 {
                font-size: 1.5rem;
                letter-spacing: 0.02rem;

                span {
                  text-transform: uppercase;
                }

                &::before {
                  content: '';
                  position: absolute;
                  background: transparentize($light, 0.8);
                }
              }

              &::before {
                content: '';
                position: absolute;
                background-position: center top;
                background-repeat: no-repeat;
                left: 0;
                right: 0;
                top: 4rem;
                height: 5rem;
                background-size: auto 5rem;

                @include media-breakpoint-up($mobile-view-breakpoint) {
                  top: 6rem;
                }
              }

              &.bank {
                background-color: $primary;

                &::before {
                  background-image: url('../images/icon-bank.svg');
                }
              }

              &.admin {
                background-color: $gray-600;


                &::before {
                  background-image: url('../images/icon-admin.svg');
                }
              }

              &.kyc {
                background-color: $gray-400;

                &::before {
                  background-image: url('../images/icon-kyc.svg');
                }
              }

              &.security {
                background-color: $secondary;

                &::before {
                  background-image: url('../images/icon-security.svg');
                }
              }

              &::after {
                content: '';
                position: absolute;
                width: 2rem;
                height: 2rem;
                right: 1rem;
                bottom: 1rem;
                background: url('../images/link-arrow.svg') no-repeat center center;
                background-size: contain;
                transition: all 0.3s ease-in-out;
              }

              &:hover {
                z-index: 9;

                &::after {
                  right: 0.2rem;
                }
              }
            }
          }
        }
      }
    }

    &#book-demo {
      padding-top: 15rem;
      padding-bottom: 15rem;
      z-index: 1;
      background: url('../images/book-demo-bg.jpg') no-repeat center center;
      background-size: cover;
      position: relative;
      transition: all 0.2s ease-in-out;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: transparentize($gray-800, 0.5);
      }

      .section-content-wrapper {
        .section-content {
          h2 {
            text-transform: uppercase;

            a {
              color: $light;
              @extend .more;

              &::after {
                background-image: url('../images/link-arrow.svg');
                margin-bottom: -0.5rem;
              }
            }
          }
        }
      }
    }

    &#count {
      padding-top: 5rem;
      padding-bottom: 5rem;

      .section-content-wrapper {
        .section-content {
          text-align: center;

          h2 {
            margin-top: 1.5rem;
            font-weight: $font-weight-bold;
            font-size: 3.5rem;
            letter-spacing: 0.02rem;
          }

          .sub-heading {
            color: $body-color;
            font-size: 1.5rem;
            font-weight: $font-weight-light;
            text-transform: uppercase;
            margin-top: 0.5rem;
          }

          &:first-child {
            font-weight: $font-weight-light;
          }

          @include media-breakpoint-up($mobile-view-breakpoint) {
            &:first-child {
              border-right: 2px solid $dark;
            }

            &:last-child {
              border-left: 2px solid $dark;
            }
          }
        }
      }
    }

    &#about {
      position: relative;
      z-index: 1;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        margin-top: 5rem;
        margin-bottom: 5rem;
      }

      .section-content-wrapper {
        position: relative;
        padding-top: 0;
        padding-bottom: 5rem;

        @include media-breakpoint-up($mobile-view-breakpoint) {
          padding-top: 15rem;
          padding-bottom: 15rem;
        }

        &::before {
          display: none;
          content: '';
          background: url('../images/about-bg.jpg') no-repeat center center;
          background-size: cover;
          position: absolute;
          left: 0;
          width: 50%;
          top: -5rem;
          bottom: -5rem;
          z-index: 1;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            display: block;
          }
        }

        &::after {
          display: none;
          content: '';
          background: transparentize($light, 0.6);
          position: absolute;
          left: 0;
          width: 50%;
          top: -5rem;
          bottom: -5rem;
          z-index: 2;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            display: block;
          }
        }

        .section-content {
          z-index: 3;

          h2 {
            color: $body-color;
            text-align: center;
            font-size: 2rem;
            text-transform: uppercase;
            padding-top: 5rem;
            padding-bottom: 5rem;

            @include media-breakpoint-up($mobile-view-breakpoint) {
              margin-right: 1rem;
              padding-top: 0;
              padding-bottom: 0;
            }

            span {
              color: $primary;
            }

            &.vertical {
              @include media-breakpoint-up($mobile-view-breakpoint) {
                transform: rotate(-90deg);
                transform-origin: right;
              }
            }

            &::before {
              content: '';
              background: url('../images/about-bg.jpg') no-repeat center center;
              background-size: cover;
              position: absolute;
              left: -15px;
              top: 0;
              bottom: 0;
              right: -15px;
              z-index: -2;

              @include media-breakpoint-up($mobile-view-breakpoint) {
                display: none;
              }
            }

            &::after {
              content: '';
              background: transparentize($light, 0.6);
              position: absolute;
              left: -15px;
              top: 0;
              bottom: 0;
              right: -15px;
              z-index: -1;

              @include media-breakpoint-up($mobile-view-breakpoint) {
                display: none;
              }
            }
          }

          .content-body {
            @include media-breakpoint-up($mobile-view-breakpoint) {
              padding: 0 4rem;
            }

            h3 {
              font-weight: $font-weight-light;
              text-transform: uppercase;
              margin-top: 5rem;
            }

            a {
              display: block;
              white-space: nowrap;

              &.more {
                color: $light;

                &::after {
                  background-image: url('../images/link-arrow.svg');
                }
              }
            }
          }
        }
      }
    }

    &#contact {
      padding-top: 5rem;
      padding-bottom: 10rem;
      background-color: $gray-300;
      position: relative;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        padding-bottom: 20rem;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 20rem;
        background: url('../images/skyline.svg') no-repeat center calc(100% + 5px);
        background-size: contain;
        border-bottom: 3px solid $gray-500;
      }

      .section-content-wrapper {
        .section-content {
          padding: 1.5rem 3rem;

          h2 {
            text-align: center;
            text-transform: uppercase;

            span {
              color: $primary;
            }
          }

          h3 {
            font-size: 1.3rem;
            margin-top: 1.5rem;
            color: $primary;
          }

          ul {
            margin-left: 0;
            padding-left: 0;

            li {
              list-style: none;
              margin-left: 0;
              padding-left: 0;
            }
          }

          a {
            &.book-demo {
              font-size: 1.3rem;
              white-space: nowrap;
            }
          }

          .map {
            width: 100%;
            height: 300px;
            background: url('../images/map.svg') no-repeat center right;
            background-size: contain;

            @include media-breakpoint-up($mobile-view-breakpoint) {
              height: 500px;
            }
          }
        }
      }
    }

    &#signup-form {
      padding-top: 2rem;
      padding-bottom: 2rem;

      .section-content-wrapper {
        .section-content {
          padding: 2rem 3rem;
          margin-top: -7rem;
          background-color: $white;

          h2 {
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 2rem;

            span {
              color: $primary;
            }
          }
        }
      }
    }

    &#product-page {
      padding-top: 2rem;

      .section-content-wrapper {
        .section-content {
          padding: 2rem 3rem;
          margin-top: -7rem;
          background-color: $white;

          .product-tabs {
            ul {
              &.nav-tabs {
                border: none;

                li {
                  a {
                    border: none;
                    color: $body-color;
                    text-transform: uppercase;
                    border-bottom: 1px solid transparent;

                    &.active {
                      border-bottom: 1px solid $primary;
                    }
                  }
                }
              }
            }
          }

          h2 {
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 2rem;

            span {
              color: $primary;
            }
          }
        }
      }

      & + #product-page-detail {
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin-bottom: 4rem;

        background: $gray-200;
        color: $body-color;

        .section-content-wrapper {
          .section-content {
            padding: 2rem 3rem;

            table {
              &.product-overview {
                width: 100%;
                margin: 1rem auto;

                td,
                th {
                  padding-top: 0.5rem;
                  padding-bottom: 0.5rem;
                }

                th {
                  &[scope='row'] {
                    color: $primary;
                    font-weight: $font-weight-normal;
                  }
                }

                td {
                  text-align: right;
                }
              }
            }

            &.product-facts {
              position: relative;

              &::before {
                content: '';
                position: absolute;
                right: 0;
                left: 0;
                top: -2rem;
                bottom: -2rem;
                z-index: -1;

                @include media-breakpoint-up($mobile-view-breakpoint) {
                  background: $secondary;
                }
              }

              h3 {
                @include media-breakpoint-up($mobile-view-breakpoint) {
                  color: $light;
                }

                font-weight: $font-weight-light;
              }

              .progress {
                height: 2.5rem;
                background-color: $light;
              }
            }
          }
        }
      }
    }

    &#product-list-page {
      padding-top: 2rem;

      .section-content-wrapper {
        .section-content {
          padding: 2rem 3rem 0;
          margin-top: -7rem;
          background-color: $white;

          h2 {
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 2rem;

            span {
              color: $primary;
            }
          }
        }
      }

      & + #product-list-page-content {
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin-bottom: 4rem;
        background: $white;

        .section-content-wrapper {
          .section-content {
            padding: 0 3rem 2rem;

            .product-list-filter {
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;
              padding: 1rem;

              .btn-filter {
                background-color: $gray-400;
                background-image: url('../images/filter.svg');
                background-repeat: no-repeat;
                background-position: 1rem center;
                background-size: 2rem;
                padding: 1rem 2rem 1rem 4rem;
              }
            }

            .product-item {
              background: $gray-200;
              padding: 1rem;
              margin-bottom: 2rem;

              @include media-breakpoint-up($mobile-view-breakpoint) {
                padding: 2rem;
                background-clip: content-box;
                margin-bottom: 0;
              }

              .product-item-content {

                @include media-breakpoint-up($mobile-view-breakpoint) {
                  padding: 2rem;
                }

                h4 {
                  font-size: 1.5rem;
                  text-transform: uppercase;

                  & + h5 {
                    font-size: 1.3rem;
                  }
                }

                .product-image {
                  position: relative;
                  width: 100%;
                  height: 10rem;
                  margin-bottom: 1rem;

                  @include media-breakpoint-up($mobile-view-breakpoint) {
                    height: 15rem;
                  }

                  &::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    background-position: center center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-image: url('../images/product-1.jpg');
                  }
                }

                table {
                  &.product-overview {
                    width: 100%;
                    margin: 1rem auto;

                    td,
                    th {
                      padding-top: 0.5rem;
                      padding-bottom: 0.5rem;
                    }

                    th {
                      &[scope='row'] {
                        color: $primary;
                        font-weight: $font-weight-normal;
                      }
                    }

                    td {
                      text-align: right;
                    }
                  }
                }

                .progress {
                  height: 2.5rem;
                  background-color: $light;
                  border: 2px solid $secondary;
                }
              }

              @for $i from 1 through 4 {
                &.product-#{$i} {
                  .product-item-content {
                    .product-image {
                      &::before {
                        background-image: url('../images/product-#{$i}.jpg');
                      }
                    }
                  }
                }
              }

              &:nth-child(2n) {
                clear: right;
              }

              &:nth-child(2n+1) {
                clear: left;
              }
            }

            .product-list-pagination {
              padding: 1rem;
              text-align: center;

              nav {
                margin: 0 auto;

                ul {
                  &.pagination {
                    justify-content: center;

                    li {
                      border-radius: 0;
                      margin: 3px;

                      &.previous,
                      &.next {
                        a {
                          border: none;
                          text-indent: -99999px;
                          background: url('../images/link-arrow-red.svg') no-repeat center center;
                          background-size: contain;
                        }

                        &.disabled {
                          a {
                            background-image: url('../images/link-arrow-gray.svg');
                          }
                        }
                      }

                      &.previous {
                        a {
                          transform: rotate(-180deg);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &#faq-page {
      padding-top: 2rem;

      .section-content-wrapper {
        .section-content {
          padding: 2rem 3rem;
          margin-top: -7rem;
          background-color: $white;

          h2 {
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 2rem;

            span {
              color: $primary;
            }
          }
        }
      }

      & + #faq-page-content {
        .section-content-wrapper {
          .section-content {
            padding-bottom: 2rem;

            .faq-filter {
              padding-bottom: 2rem;

              .input-group {
                .form-control {
                  border-right: none !important;
                }

                .input-group-text {
                  background: transparent;
                  border-left: none !important;

                  img {
                    width: 1.2rem;
                  }
                }
              }
            }

            .faq-group {
              padding-bottom: 1.5rem;

              .faq-item {
                margin-top: 2rem;
                margin-bottom: 1rem;

                .faq-item-content {
                  .faq-header {
                    h4 {
                      font-size: 1.3rem;
                      color: $primary;
                      font-weight: 600;
                    }
                  }

                  .faq-detail {
                    font-weight: 600;
                  }
                }
              }

              h3 {
                &.faq-category {
                  font-size: 1.3rem;
                  font-weight: 600;
                  margin: 0;

                  & + .faq-item {
                    margin-top: 1rem;
                  }
                }
              }
            }

            .faq-sidebar {
              .faq-other-queries {
                padding: 1.5rem;
                background-color: $gray-200;

                h4 {
                  font-size: 1.3rem;
                  font-weight: 600;
                }

                .sub-heading {
                  margin-top: 0.5rem;
                  margin-bottom: 0rem;
                  font-size: 1.3rem;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }

    &#who-we-are-page-top {
      padding-top: 2rem;

      .section-content-wrapper {
        .section-content {
          padding: 2rem 3rem;
          margin-top: -7rem;
          background-color: $white;

          h2 {
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 2rem;

            span {
              color: $primary;
            }
          }
        }
      }
    }

    &#who-we-are-page-body {
      padding-top: 2rem;
      padding-bottom: 2rem;
      margin-top: 6rem;
      margin-bottom: 6rem;
      position: relative;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        &::before {
          content: '';
          position: absolute;
          right: 0;
          top: -4rem;
          bottom: -4rem;
          left: 50%;
          background-color: $gray-200;
        }
      }

      .section-content-wrapper {
        .section-content {
          padding: 2rem 3rem;

          h2 {
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 2rem;

            span {
              color: $primary;
            }
          }

          &.partner-logo-container {
            h2 {
              color: $primary;
            }

            .partner-logos {
              .partner-logo {
                text-align: center;
                padding-top: 3rem;

                img {
                  display: block;
                  margin: 0 auto;
                }
              }
            }
          }

          &.team-container {
            padding: 2rem !important;
            background-color: $gray-200;
            margin-top: 2rem;

            @include media-breakpoint-up($mobile-view-breakpoint) {
              padding: 5rem 0;
              margin: -6rem 0;
            }

            color: $body-color;

            h2 {
              text-transform: none;

              span {
                text-transform: uppercase;
                color: $primary;
              }
            }

            .team-members {
              .team-member {
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;

                h3 {
                  text-transform: uppercase;
                  font-size: 1.5rem;

                  & + .team-member-position {
                    color: $primary;
                    margin-bottom: 0.8rem;
                  }
                }
              }
            }

            .members-link {
              text-align: center;

              @include media-breakpoint-up($mobile-view-breakpoint) {
                text-align: right;
              }

              .more {
                &::after {
                  transform: rotate(90deg);
                }

                &:hover {
                  &::after {
                    transform: rotate(0deg);
                  }
                }
              }
            }
          }
        }
      }
    }

    &#bigger-picture {
      padding-top: 2rem;
      padding-bottom: 2rem;
      position: relative;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        &::after {
          content: '';
          position: absolute;
          right: 0;
          left: 45%;
          top: 4rem;
          bottom: 4rem;
          background: url('../images/bigger-picture-bg.jpg') left center no-repeat;
          background-size: cover;
        }
      }

      .section-content-wrapper {
        .section-content {
          h2 {
            text-transform: uppercase;
            margin-bottom: 0;

            span {
              color: $primary;
            }
          }

          .sub-heading {
            color: $dark;
            font-size: 1.6rem;
            margin-top: 0;
          }

          .bigger-picture-more {
            padding: 6rem 0 4rem 0;

            img {
              height: 6rem;
            }

            .more-link {
              margin-top: 1.5rem;

              .more {

              }
            }
          }
        }
      }
    }

    &.bigger-picture-demo {
      background-image: url('../images/book-demo-2-bg.jpg') !important;
      background-position: center 10% !important;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        background-position: center 40% !important;
      }
    }

    &#use-case-of-tokenisation-body {
      .section-content-wrapper {
        .section-content {
          .content-body {
            h4 {
              color: $primary;
              font-size: 1.2rem;
            }

            p {
              margin-bottom: 2rem;
            }
          }
        }
      }
    }

    &.issuance-page-title {
      padding-top: 2rem;

      .section-content-wrapper {
        .section-content {
          padding: 2rem 3rem 0 3rem;
          margin-top: -7rem;
          background-color: $white;

          h2 {
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 2rem;

            span {
              color: $primary;
            }
          }
        }
      }
    }

    &.issuance-page-body {
      padding-bottom: 2rem;

      .section-content-wrapper {
        .section-content {
          padding: 0 3rem 1.5rem 3rem;

          h2 {
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 2rem;

            span {
              color: $primary;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down($mobile-view-breakpoint) {
    .section-content {
      padding: 0 !important;
    }
  }
}