header {
  .header-nav {
    .navbar-brand {
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        margin-right: 1rem;
        margin-left: 0;
        text-align: left;
      }

      .logo-img {
        width: 200px;
        height: auto;
      }
    }

    .header-menu-container {
      max-width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        margin-right: 0;
      }

      .header-menu {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: center;

        @include media-breakpoint-up($mobile-view-breakpoint) {
          justify-content: end;
        }

        .login-links {
          display: flex;
          align-items: stretch;
          justify-content: space-evenly;
          flex-wrap: nowrap;
          min-width: 15rem;
          margin-right: 2rem;

          @include media-breakpoint-up($mobile-view-breakpoint) {
            width: 300px;
          }

          a {
            &.login-link {
              width: 3rem;
              height: 2rem;
              margin-bottom: 1rem;
              position: relative;
              display: inline-block;

              &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                transition: all 0.3s ease-in-out;
              }

              &::after {
                text-transform: uppercase;
                font-weight: 400;
                font-size: 0.6rem;
                text-align: center;
                color: $dark;
                letter-spacing: 1px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -1rem;
              }

              @include media-breakpoint-up($mobile-view-breakpoint) {
                width: 5rem;
                height: 4rem;

                &::after {
                  font-size: 0.7rem;
                }
              }

              &.investor {
                &::before {
                  background-image: url('../images/icon-investor.svg');
                }

                &::after {
                  content: 'Investor';
                }

                @include media-breakpoint-up($mobile-view-breakpoint) {

                }
              }

              &.issuer {
                &::before {
                  background-image: url('../images/icon-issuer.svg');
                }

                &::after {
                  content: 'Issuer';
                }

                @include media-breakpoint-up($mobile-view-breakpoint) {

                }
              }

              &.oracle {
                &::before {
                  background-image: url('../images/icon-oracle.svg');
                }

                &::after {
                  content: 'Oracle';
                }

                @include media-breakpoint-up($mobile-view-breakpoint) {

                }
              }

              &.operator {
                &::before {
                  background-image: url('../images/icon-operator.svg');
                }

                &::after {
                  content: 'Operator';
                }

                @include media-breakpoint-up($mobile-view-breakpoint) {

                }
              }

              &:hover {
                &::before {
                  -webkit-transform: rotate(90deg);
                  transform: rotate(90deg);

                  @include media-breakpoint-up($mobile-view-breakpoint) {
                    -webkit-transform: rotate(90deg);
                    transform: rotate(90deg);
                  }
                }
              }
            }
          }
        }

        .hamburger-menu {
          position: relative;

          .menu-icon {
            padding-top: 0;
            width: 1.5rem;
            z-index: 9;
            position: absolute;
            left: -1.5rem;
            cursor: pointer;

            &::before,
            &::after,
            & > div {
              content: '';
              background-color: $dark;
              border-radius: 3px;
              display: block;
              height: 5px;
              margin: 5px 0;
              transition: all .2s ease-in-out;
            }

            @include media-breakpoint-up($mobile-view-breakpoint) {
              padding-top: 1rem;
              width: 2rem;

              &::before,
              &::after,
              & > div {
                margin: 7px 0;
              }
            }
          }

          &.open {
            .menu-icon {
              &::before {
                transform: translateY(10px) rotate(135deg);

                @include media-breakpoint-up($mobile-view-breakpoint) {
                  transform: translateY(12px) rotate(135deg);
                }
              }

              &::after {
                transform: translateY(-10px) rotate(-135deg);

                @include media-breakpoint-up($mobile-view-breakpoint) {
                  transform: translateY(-12px) rotate(-135deg);
                }
              }

              & > div {
                transform: scale(0);
              }
            }
          }

          .top-menu {
            position: absolute;
            right: -1.5rem;
            top: 0;
            background: $white;
            padding: 3rem;
            z-index: 5;
            width: 20.5rem;
            max-width: 100vw;

            @include media-breakpoint-up($mobile-view-breakpoint) {
              padding: 5rem;
              width: 30rem;
            }

            ul {
              li {
                list-style: none;
                font-size: 1.3rem;

                a {
                  color: $body-color;
                  padding-top: 0.5rem;
                  padding-bottom: 0.5rem;
                  display: block;

                  &:hover {
                    color: $primary;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

section {
  &.top-billboard {
    background: url('../images/top-billboard-bg.jpg') no-repeat center center;
    background-size: cover;
    min-height: 60vh;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(to bottom, transparentize($gray-200, 0.4) 5%, transparent 50%);
    }

    @include media-breakpoint-up($mobile-view-breakpoint) {
      min-height: 80vh;
    }

    .top-billboard-content {
      margin: 6vh auto;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        margin: 10vh auto;
      }

      h1 {
        text-transform: uppercase;
        font-weight: $font-weight-light;

        strong {
          font-weight: $font-weight-base;
        }
      }

      a {
        color: $light;
        font-size: 1.3rem;
        font-weight: $font-weight-light;
        line-height: 3rem;
        text-decoration: none;
        display: block;
        position: relative;
        margin-top: 15rem;

        &::after {
          content: '';
          display: inline-block;
          background: url('../images/link-triangle.svg') no-repeat center right;
          background-size: contain;
          width: 3rem;
          height: 3rem;
          margin-left: 1rem;
          margin-bottom: -1rem;
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          &::after {
            margin-left: 1.5rem;
          }
        }

        @include media-breakpoint-up($mobile-view-breakpoint) {
          font-size: 1.5rem;
          line-height: 6rem;

          &::after {
            width: 6rem;
            height: 6rem;
            margin-left: 2rem;
            margin-bottom: -2rem;
          }

          &:hover {
            &::after {
              margin-left: 4rem;
            }
          }
        }
      }
    }

    &#signup-billboard {
      background-image: url('../images/signup.jpg');
      background-position: center bottom;
      min-height: 40vh;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        min-height: 60vh;
      }
    }

    &#product-page-billboard {
      background-position: center bottom;
      min-height: 40vh;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        min-height: 60vh;
      }

      &.fluid-amity {
        background-image: url('../images/product-1.jpg');
      }
    }

    &#product-list-billboard {
      background-image: url('../images/product-list.jpg');
      background-position: center bottom;
      min-height: 40vh;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        min-height: 60vh;
      }
    }

    &#faq-billboard {
      background-position: center bottom;
      min-height: 40vh;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        min-height: 60vh;
      }

      &.faq-page-billboard {
        background-image: url('../images/faq-bg.jpg');
      }
    }

    &#who-we-are-page-billboard {
      background-position: center center;
      min-height: 40vh;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        min-height: 60vh;
      }

      &.top-billboard {
        background-image: url('../images/who-we-are-bg.jpg');
      }
    }

    &#use-case-of-tokenisation-billboard {
      background-position: center center;
      min-height: 40vh;

      @include media-breakpoint-up($mobile-view-breakpoint) {
        min-height: 60vh;
      }

      &.top-billboard {
        background-image: url('../images/use-case-bg.jpg');
      }
    }
  }
}