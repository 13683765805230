/**
 * Fonts
 */

@import "fonts";

$font-family-base: 'Futura',
'Nunito',
sans-serif;
$font-size-base: 1.1rem;
$font-weight-base: 400;
$headings-font-weight: 400;

/**
 * Colors
 */
$primary: #e2000b;
$secondary: #e8c09e;
$soft-grey: #adaead;
$light-grey: #f4f4f4;

/**
 * Forms and Buttons
 */

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-border-radius-sm: 0;
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;
$btn-padding-x: 1.5rem;
$form-group-margin-bottom: 1.5rem;
$progress-border-radius: 0;

/**
 * Media breakpoint for "mobile view"
 */
$mobile-view-breakpoint: md;