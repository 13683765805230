.modal {
  .modal-content {
    border-radius: 0;
    border: none;
    padding: 1rem;

    .modal-header {
      border: none;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      background-origin: content-box;
      min-height: 7rem;
      align-items: center;

      .close {
        font-size: 5rem;
        font-weight: 300;
        color: $dark;
        text-shadow: none;

        &:hover {
          transform: scale(1.1) rotate(180deg);
          transition-duration: .3s;
        }
      }
    }
  }

  &.login-modal {
    .modal-content {
      .input-group {
        input {

          &[type=text],
          &[type=password] {
            background: transparent;
            border-radius: 0;
            border: 2px solid $light;
            text-align: center;
            border-right: none;
            outline: none;
            box-shadow: none;

            &:focus,
            &:active,
            &:hover {
              outline: none;
              box-shadow: none;
            }
          }
        }

        .input-group-append {
          .input-group-text {
            border-radius: 0;
            background: transparent;
            border: 2px solid $light;
            border-left: none;

            img {
              height: 20px;
            }
          }
        }
      }

      .button {
        text-align: center;

        .login-button {
          background: transparentize($light, 0.7);
          border-radius: 0;
          border: 2px solid transparent;
          margin-top: 1rem;
          margin-bottom: 1rem;
        }

        .signup-button {
          background: transparent;
          border-radius: 0;
          border: 2px solid $light;
          color: $dark;
          padding-left: 3rem;
          padding-right: 3rem;
        }
      }
    }

    &.investor {
      .modal-content {
        background-color: $gray-300;

        .modal-header {
          background-image: url('../images/icon-investor.svg');
        }
      }
    }

    &.issuer {
      .modal-content {
        background-color: $gray-300;

        .modal-header {
          background-image: url('../images/icon-issuer.svg');
        }
      }
    }

    &.oracle {
      .modal-content {
        background-color: $gray-300;

        .modal-header {
          background-image: url('../images/icon-oracle.svg');
        }
      }
    }

    &.operator {
      .modal-content {
        background-color: $gray-300;

        .modal-header {
          background-image: url('../images/icon-operator.svg');
        }
      }
    }
  }

  &.regulatory-comfort-modal {
    .modal-content {
      background-color: $gray-300;
    }
  }
}

.status-list {
  padding: 0;
  border: 0 solid $soft-grey;
  border-width: 0 0 1px 1px;

  .status-entry {
    list-style-position: inside;
    padding: 5px 20px;
    border: 0 solid $soft-grey;
    border-width: 1px 1px 0 0;

    &.active {
      background-color: $secondary;
    }
  }
}