// .rangeslider,
// .rangeslider__fill {
//     display: block;
//     -moz-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
//     -webkit-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
//     box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
// }

.rangeslider {
    background: $light-grey;
    position: relative;
}

.rangeslider--horizontal {
    height: 10px;
    width: 100%;
    border: none;
}

.rangeslider--vertical {
    width: 10px;
    min-height: 150px;
    max-height: 100%;
    height: 100%;
}

.rangeslider--disabled {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    opacity: 0.4;
}

.rangeslider__fill {
    background: $primary;
    position: absolute;
}

.rangeslider--horizontal .rangeslider__fill {
    top: 0;
    height: 100%;
}

.rangeslider--vertical .rangeslider__fill {
    bottom: 0;
    width: 100%;
}

.rangeslider__handle {
    background: $primary;
    border: 1px solid $primary;
    cursor: pointer;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: absolute;
    border-radius: 16px;
}

.rangeslider__handle:active,
.rangeslider--active .rangeslider__handle {
    background-color: darken($color: $primary, $amount: 10);
}

.rangeslider--horizontal .rangeslider__handle {
    top: -3px;
    touch-action: pan-y;
    -ms-touch-action: pan-y;
}

.rangeslider--vertical .rangeslider__handle {
    left: -3px;
    touch-action: pan-x;
    -ms-touch-action: pan-x;
}