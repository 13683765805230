.wizard {
  .card {
    border: none;
    border-radius: 0;

    .card-header {
      border-radius: 0;
      border: none;
      background: transparent;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      cursor: pointer;

      &.text-centered {
        text-align: center;
        padding: 1rem;
      }

      h2 {
        text-align: left !important;
        font-size: 1.2rem;
        letter-spacing: normal;
        white-space: nowrap;

        &.h2-title {
          display: table;
          width: 100%;
        }

        .title-header,
        .title-number,
        .title-info {
          display: table-cell;
          padding: 1rem;
        }

        @include media-breakpoint-up($mobile-view-breakpoint) {
          font-size: 1.5rem;
          letter-spacing: 0.1rem;
        }

        .title-header {
          white-space: normal;
          width: auto;
          /* overflow: hidden; */
        }

        .title-number {
          background: $primary !important;
          padding: 0.5rem;
          color: $light !important;
          text-align: center;
          letter-spacing: 0;
          width: 50px;
        }

        .title-info {
          width: 120px;
        }

        a {
          color: $body-color;
        }
      }

      &.collapsed {
        background-color: $light;

        h2 {
          .title-number {
            background: $gray-800 !important;
          }
        }
      }
    }

    .card-body {
      padding-left: 4rem;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 24px;
        top: 0;
        bottom: 0;
        width: 3px;
        background: $primary;
      }
    }

    // &:last-child {
    //   .card-body {
    //     &::after {
    //       content: '';
    //       position: absolute;
    //       left: 2rem;
    //       bottom: 0;
    //       right: 0;
    //       height: 3px;
    //       background: $primary;
    //     }
    //   }
    // }

    &.disabled {
      pointer-events: none;

      .card-header {
        cursor: default;

        h2 {
          color: $gray-600 !important;

          .title-number {
            background-color: $gray-500 !important;
          }

          a {
            color: $gray-500 !important;
          }
        }
      }

      .card-body {
        display: none;
      }
    }
  }
}

.text-centered {
  text-align: center;
  padding: 1rem;
}

.btn-remove {
  margin: 0;
  padding: 6px 0;
}
