form {
  label {
    sup {
      color: $primary;
    }
  }

  .form-control {
    &.accepted {
      & + .input-group-append {
        .input-group-text {
          background-color: transparentize($success, 0.7);
        }
      }
    }

    &.denied {
      & + .input-group-append {
        .input-group-text {
          background-color: transparentize($primary, 0.7);
        }
      }
    }

    &[multiple] {
      &.is-invalid, &.is-valid, &:valid, &:invalid {
        background-position: right add($input-height-inner-quarter, 0.4rem) center;
      }
    }
  }

  .input-group-append {
    .input-group-text {
      img {
        width: 1rem;
        height: 1rem;
      }
    }
  }
}

.custom-select {
  border-radius: 0;

  &.greyed {
    background-image: none;
    background-color: $gray-200;
  }
}

.width-100 {
  width: 100px;
}

.width-p80 {
  width: 80%;
}

.date-range-picker {
  max-width: 250px;
}

.radio-headsup {
  display: inline-block;
  margin-left: 8px;
}

.overflow-hidden {
  overflow: hidden;
}

.help-icon {
  color: white;
  font-weight: 500;
  background-color: $gray-500;
  border: 0;
  border-radius: 10px;
  display: inline-block;
  font-size: 12px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  transform: translateX(4px) translateY(-10px);
  cursor: pointer;
  transition: all .4s;

  &:hover {
    background-color: $primary;
    transition: all .4s;
  }
}

// Solution for changing select color or select box
// select option:checked:after {
//   content: attr(title);
//   background: #666;
//   color: #fff;
//   position: absolute;
//   width: calc(100% - 81px);
//   left: 34px;
//   border: none;
//   height: 24px;
// }